import { lazy } from "react";

export const routeNames = {
  home: { name: "Home", path: "/" },
  index: { name: "Index", path: "/Home" },
  aboutUs: { name: "AboutUs", path: "/AboutUs" },
  howItWorks: { name: "HowItWorks", path: "/HowItWorks" },
  plans: { name: "Plans", path: "/Plans" },
  blog: { name: "Blog", path: "/Blog" },
  privacyPolicy: { name: "PrivacyPolicy", path: "/PrivacyPolicy" },
  terms: { name: "Terms", path: "/Terms" },
  contactUs: { name: "ContactUs", path: "/ContactUs" },
  login: { name: "Login", path: "/Login" },
  register: { name: "Register", path: "/Register" },
  forgotPwd: { name: "ForgotPwd", path: "/ForgotPassword" },
  properties: { name: "Properties", path: "/Properties" },
  propertyDetails: { name: "PropertyDetails", path: "/PropertyDetails" },
  agents: { name: "Agents", path: "/Agents" },
  agentdetails: { name: "AgentDetails", path: "/AgentDetails" },
  dashboard: { name: "Dashboard", path: "/User/Dashboard" },
  createprofile: {
    name: "CreateProfile",
    path: "/User/CreateProfile/:ProfileType",
  },
  ownerprofile: {
    name: "OwnerProfile",
    path: "/User/Owner-Profile",
  },
  ownerprofileedit: {
    name: "OwnerProfileEdit",
    path: "/User/Owner-Profile-Edit",
  },
  ownernotifications: {
    name: "OwnerNotifications",
    path: "/User/Owner-Notifications",
  },
  profiles: { name: "Profiles", path: "/User/Profiles" },
  settings: { name: "Settings", path: "/User/Settings" },
  upgradeplan: { name: "UpgradePlan", path: "/User/UpgradePlan" },
  ownerproperties: { name: "OwnerProperties", path: "/User/Owners/Properties" },
  ownerlistedproperties: {
    name: "OwnerListedProperties",
    path: "/User/Owners/ListedProperties",
  },
  ownerpartnershipproperties: {
    name: "OwnerPartnershipProperties",
    path: "/User/Owners/PartnershipProperties",
  },
  ownerviewproperty: {
    name: "OwnerViewProperty",
    path: "/User/Owners/ViewProperty",
  },
  addresidentialproperty: {
    name: "AddResidentialProperty",
    path: "/User/Owners/AddResidentialProperty",
  },
  manageresidentialproperty: {
    name: "ManageResidentialProperty",
    path: "/User/Owners/ManageResidentialProperty",
  },
  addcommercialproperty: {
    name: "AddCommercialProperty",
    path: "/User/Owners/AddCommercialProperty",
  },
  managecommercialproperty: {
    name: "ManageCommercialProperty",
    path: "/User/Owners/ManageCommercialProperty",
  },
  ownerassignproperty: {
    name: "ManageAssignProperty",
    path: "/User/Owners/AssignProperty",
  },
  agentproperties: {
    name: "AgentProperties",
    path: "/User/Agents/Properties",
  },
  agentviewproperty: {
    name: "AgentViewProperty",
    path: "/User/Agents/ViewProperty",
  },
  agentassignedproperties: {
    name: "AgentAssignedProperties",
    path: "/User/Agents/AssignedProperties",
  },
  tenantconnectedproperties: {
    name: "TenantConnectedProperties",
    path: "/User/Tenants/ConnectedProperties",
  },
  tenantviewproperty: {
    name: "TenantViewProperty",
    path: "/User/Tenants/ViewProperty",
  },
  paymentsaccounts: {
    name: "PaymentsAccounts",
    path: "/User/Payments/Accounts",
  },
  paymentscreateaccount: {
    name: "PaymentsCreateAccount",
    path: "/User/Payments/CreateAccount",
  },
  paymentsinvoices: {
    name: "PaymentsInvoices",
    path: "/User/Payments/Invoices",
  },
  connectionsowners: {
    name: "ConnectionsOwners",
    path: "/User/Connections/Owners",
  },
  connectionsagents: {
    name: "ConnectionsAgents",
    path: "/User/Connections/Agents",
  },
  connectionstenants: {
    name: "ConnectionsTenants",
    path: "/User/Connections/Tenants",
  },
  tenantprofile: {
    name: "TenantProfile",
    path: "/User/Tenant-Profile",
  },
  tenantprofileedit: {
    name: "TenantProfileEdit",
    path: "/User/Tenant-Profile-Edit",
  },
  tenantnotifications: {
    name: "TenantNotifications",
    path: "/User/Tenant-Notifications",
  },
  tenantpayments: {
    name: "TenantPayments",
    path: "/User/Tenant-Payments",
  },
  tenantcheckout: {
    name: "TenantCheckout",
    path: "/User/Tenant-Checkout",
  },
  agentprofile: {
    name: "AgentProfile",
    path: "/User/Agent-Profile",
  },
  agentprofileedit: {
    name: "AgentProfileEdit",
    path: "/User/Agent-Profile-Edit",
  },
  agentnotifications: {
    name: "AgentNotifications",
    path: "/User/Agent-Notifications",
  },
  owneragreementtemplates: {
    name: "OwnerAgreementTemplates",
    path: "/User/Owner-Agreement-Templates",
  },
  ownersendagreement: {
    name: "OwnerSendAgreement",
    path: "/User/Owner-Send-Agreement",
  },
  ownerpreviewagreement: {
    name: "OwnerPreviewAgreement",
    path: "/User/Owner-Agreement-Preview",
  },
  mydocuments: {
    name: "MyDocuments",
    path: "/User/Documents/MyDocuments",
  },
  viewdocument: {
    name: "ViewDocument",
    path: "/User/Documents/ViewDocument",
  },
  sharedocument: {
    name: "ShareDocument",
    path: "/User/Documents/ShareDocument",
  },
  shareddocuments: {
    name: "SharedDocuments",
    path: "/User/Documents/SharedDocuments",
  },
  sharedfolders: {
    name: "SharedFolders",
    path: "/User/Documents/SharedFolders",
  },
  sharedusers: {
    name: "SharedUsers",
    path: "/User/Documents/SharedUsers",
  },
  comingup: { name: "ComingUp", path: "/User/ComingUp" },
  notFound: { name: "NotFound", path: "/*" },
};

const routes = [
  {
    path: routeNames.home.path,
    name: routeNames.home.name,
    element: lazy(() => import("../pages/Home")),
    isexact: true,
  },
  {
    path: routeNames.index.path,
    name: routeNames.index.name,
    element: lazy(() => import("../pages/Home")),
    isexact: true,
  },
  {
    path: routeNames.aboutUs.path,
    name: routeNames.aboutUs.name,
    element: lazy(() => import("../pages/AboutUs")),
    isexact: true,
  },
  {
    path: routeNames.howItWorks.path,
    name: routeNames.howItWorks.name,
    element: lazy(() => import("../pages/HowItWorks")),
    isexact: true,
  },
  {
    path: routeNames.privacyPolicy.path,
    name: routeNames.privacyPolicy.name,
    element: lazy(() => import("../pages/PrivacyPolicy")),
    isexact: true,
  },
  {
    path: routeNames.terms.path,
    name: routeNames.terms.name,
    element: lazy(() => import("../pages/Terms")),
    isexact: true,
  },
  {
    path: routeNames.plans.path,
    name: routeNames.plans.name,
    element: lazy(() => import("../pages/Plans")),
    isexact: true,
  },
  {
    path: routeNames.blog.path,
    name: routeNames.blog.name,
    element: lazy(() => import("../pages/Blog")),
    isexact: true,
  },
  {
    path: routeNames.contactUs.path,
    name: routeNames.contactUs.name,
    element: lazy(() => import("../pages/ContactUs")),
    isexact: true,
  },
  {
    path: routeNames.login.path,
    name: routeNames.login.name,
    element: lazy(() => import("../pages/Login")),
    isexact: true,
  },
  {
    path: routeNames.register.path,
    name: routeNames.register.name,
    element: lazy(() => import("../pages/Register")),
    isexact: true,
  },
  {
    path: routeNames.forgotPwd.path,
    name: routeNames.forgotPwd.name,
    element: lazy(() => import("../pages/ForgotPassword")),
    isexact: true,
  },
  {
    path: routeNames.properties.path,
    name: routeNames.properties.name,
    element: lazy(() => import("../pages/Properties")),
    isexact: true,
  },
  {
    path: routeNames.propertyDetails.path,
    name: routeNames.propertyDetails.name,
    element: lazy(() => import("../pages/PropertyDetails")),
    isexact: true,
  },
  {
    path: routeNames.agents.path,
    name: routeNames.agents.name,
    element: lazy(() => import("../pages/Agents")),
    isexact: true,
  },
  {
    path: routeNames.agentdetails.path,
    name: routeNames.agentdetails.name,
    element: lazy(() => import("../pages/AgentDetails")),
    isexact: true,
  },
  {
    path: routeNames.dashboard.path,
    name: routeNames.dashboard.name,
    element: lazy(() => import("../pages/User/Dashboard")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.createprofile.path,
    name: routeNames.createprofile.name,
    element: lazy(() => import("../pages/User/CreateProfile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.profiles.path,
    name: routeNames.profiles.name,
    element: lazy(() => import("../pages/User/Profiles")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.settings.path,
    name: routeNames.settings.name,
    element: lazy(() => import("../pages/User/Settings")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.upgradeplan.path,
    name: routeNames.upgradeplan.name,
    element: lazy(() => import("../pages/User/UpgradePlan")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerproperties.path,
    name: routeNames.ownerproperties.name,
    element: lazy(() => import("../pages/User/Owners/Properties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerlistedproperties.path,
    name: routeNames.ownerlistedproperties.name,
    element: lazy(() => import("../pages/User/Owners/ListedProperties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerpartnershipproperties.path,
    name: routeNames.ownerpartnershipproperties.name,
    element: lazy(() => import("../pages/User/Owners/PartnershipProperties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerviewproperty.path,
    name: routeNames.ownerviewproperty.name,
    element: lazy(() => import("../pages/User/Owners/ViewProperty")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.addresidentialproperty.path,
    name: routeNames.addresidentialproperty.name,
    element: lazy(() => import("../pages/User/Owners/AddResidentialProperty")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.manageresidentialproperty.path,
    name: routeNames.manageresidentialproperty.name,
    element: lazy(() =>
      import("../pages/User/Owners/ManageResidentialProperty")
    ),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.addcommercialproperty.path,
    name: routeNames.addcommercialproperty.name,
    element: lazy(() => import("../pages/User/Owners/AddCommercialProperty")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.managecommercialproperty.path,
    name: routeNames.managecommercialproperty.name,
    element: lazy(() =>
      import("../pages/User/Owners/ManageCommercialProperty")
    ),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerassignproperty.path,
    name: routeNames.ownerassignproperty.name,
    element: lazy(() => import("../pages/User/Owners/AssignProperty")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentproperties.path,
    name: routeNames.agentproperties.name,
    element: lazy(() => import("../pages/User/Agents/Properties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentviewproperty.path,
    name: routeNames.agentviewproperty.name,
    element: lazy(() => import("../pages/User/Agents/ViewProperty")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentassignedproperties.path,
    name: routeNames.agentassignedproperties.name,
    element: lazy(() => import("../pages/User/Agents/AssignedProperties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantconnectedproperties.path,
    name: routeNames.tenantconnectedproperties.name,
    element: lazy(() => import("../pages/User/Tenants/ConnectedProperties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantviewproperty.path,
    name: routeNames.tenantviewproperty.name,
    element: lazy(() => import("../pages/User/Tenants/ViewProperty")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.paymentsaccounts.path,
    name: routeNames.paymentsaccounts.name,
    element: lazy(() => import("../pages/User/Payments/Accounts")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.paymentscreateaccount.path,
    name: routeNames.paymentscreateaccount.name,
    element: lazy(() => import("../pages/User/Payments/CreateAccount")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.paymentsinvoices.path,
    name: routeNames.paymentsinvoices.name,
    element: lazy(() => import("../pages/User/Payments/Invoices")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.connectionsowners.path,
    name: routeNames.connectionsowners.name,
    element: lazy(() => import("../pages/User/Connections/Owners")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.connectionsagents.path,
    name: routeNames.connectionsagents.name,
    element: lazy(() => import("../pages/User/Connections/Agents")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.connectionstenants.path,
    name: routeNames.connectionstenants.name,
    element: lazy(() => import("../pages/User/Connections/Tenants")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerprofile.path,
    name: routeNames.ownerprofile.name,
    element: lazy(() => import("../pages/User/Owners/Profile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerprofileedit.path,
    name: routeNames.ownerprofileedit.name,
    element: lazy(() => import("../pages/User/Owners/EditProfile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownernotifications.path,
    name: routeNames.ownernotifications.name,
    element: lazy(() => import("../pages/User/Owners/Notifications")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantprofile.path,
    name: routeNames.tenantprofile.name,
    element: lazy(() => import("../pages/User/Tenants/Profile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantprofileedit.path,
    name: routeNames.tenantprofileedit.name,
    element: lazy(() => import("../pages/User/Tenants/EditProfile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantnotifications.path,
    name: routeNames.tenantnotifications.name,
    element: lazy(() => import("../pages/User/Tenants/Notifications")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantpayments.path,
    name: routeNames.tenantpayments.name,
    element: lazy(() => import("../pages/User/Tenants/Payments")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantcheckout.path,
    name: routeNames.tenantcheckout.name,
    element: lazy(() => import("../pages/User/Tenants/Checkout")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentprofile.path,
    name: routeNames.agentprofile.name,
    element: lazy(() => import("../pages/User/Agents/Profile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentprofileedit.path,
    name: routeNames.agentprofileedit.name,
    element: lazy(() => import("../pages/User/Agents/EditProfile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentnotifications.path,
    name: routeNames.agentnotifications.name,
    element: lazy(() => import("../pages/User/Agents/Notifications")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.owneragreementtemplates.path,
    name: routeNames.owneragreementtemplates.name,
    element: lazy(() => import("../pages/User/Owners/AgreementTemplates")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownersendagreement.path,
    name: routeNames.ownersendagreement.name,
    element: lazy(() => import("../pages/User/Owners/SendAgreement")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerpreviewagreement.path,
    name: routeNames.ownerpreviewagreement.name,
    element: lazy(() => import("../pages/User/Owners/AgreementPreview")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.mydocuments.path,
    name: routeNames.mydocuments.name,
    element: lazy(() => import("../pages/User/Documents/MyDocuments")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.viewdocument.path,
    name: routeNames.viewdocument.name,
    element: lazy(() => import("../pages/User/Documents/ViewDocument")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.sharedocument.path,
    name: routeNames.sharedocument.name,
    element: lazy(() => import("../pages/User/Documents/ShareDocument")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.shareddocuments.path,
    name: routeNames.shareddocuments.name,
    element: lazy(() => import("../pages/User/Documents/SharedDocuments")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.sharedfolders.path,
    name: routeNames.sharedfolders.name,
    element: lazy(() => import("../pages/User/Documents/SharedFolders")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.sharedusers.path,
    name: routeNames.sharedusers.name,
    element: lazy(() => import("../pages/User/Documents/SharedUsers")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.comingup.path,
    name: routeNames.comingup.name,
    element: lazy(() => import("../pages/User/ComingUp")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.notFound.path,
    name: routeNames.notFound.name,
    element: lazy(() => import("../pages/NotFound")),
  },
];

export default routes;
